@import url('https://fonts.googleapis.com/css2?family=Inter&family=Montserrat:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  background-color: #E5E5E5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}